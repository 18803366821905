// -----------------------------------------------------------------------------
// This file contains all application-wide Sass desktop Forms.
// -----------------------------------------------------------------------------
form {
    input, select{
        height: $height-cta !important;
    }

    input[type=date]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }
    .custom-file-label::after {
        @include input-upload-file('../uploads/images/iconos/clip.svg');
    }
    label.error, p.error {
        color: $color-rojo;
        font-style: italic;
        margin-top: 0.8rem;
        display: block;
        &.error-option {
            margin-top: 0;
            font-size: 0;
            &::before {
                content: 'Seleccione una opción.';
                font-size: 1rem;
            }
        }
    }
    select.error, input.error, textarea.error {
        border: 1px solid #ff8792;
    }

    span.error {
        border: 1px solid #ff8792;
        @include border-radius($border-radius);
    }

    .fecha-dinamica{
        background: none;
        // height: 90px;
        select{
            display: inline-block;
            width: auto;
            margin: 0 0.5rem;

            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .field-collection-item-row {
        margin-bottom: 2rem;
        input {
            width: 80%;
            display: inline;
        }
        label.error {
            position: absolute;
            display: block;
            margin-top: 0rem;
        }
        .field-collection-item-action {
            font-size: 21px;
            font-weight: 700;
            margin-left: 4px;
            position: relative;
            top: 2px;
        }
    }
    .form-group{
        .form-check {
            margin-bottom: 1rem;
            label {
                margin-left: 0.5rem;
                padding-top: .9rem;
                &.form-check-label {
                    padding-top: 0;
                    margin-right: 3rem;
                }
            }
            &.form-check-inline {
                margin-bottom: 0rem;
            }
        }
    }
    i.icon-form {
        text-align:center;
        font-size: 32px;
        color: #68A4C4;
        border-radius: 50%;
        padding: 8px;
        border: 2px dotted #a2cce3;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 55px;
    }
}

.select2-container.select2-container--default{
    width:100%!important;
    .select2-selection--single{
        height:$height-select2;
        .select2-selection__rendered{
            line-height:$height-select2;
        }
        .select2-selection__arrow{
            height:$height-select2;
        }
    }
    &.error {
        .select2-selection--single{
            border: none;
        }
    }
}

table.resultados-descuento {
    display: table;
    margin: auto;
    line-height: 2rem;
    text-align: left;
    td {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}